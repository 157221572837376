/**
 * Showing the app loading dialog
 */
export function showLoadingDataMessage(
    msg = 'Loading Application Data...',
    title = false,
    closeButton = false,
    backdrop = 'static',
    keyboard = false,
) {
    bootbox.dialog({
        title,
        message: '<p style="text-align: center; margin-bottom: 0;">' +
            '<i class="fa fa-spin fa-spinner"></i> ' +
            msg +
            '</p>',
        closeButton,
        backdrop,
        keyboard,
    });
}

/**
 * Hide the app loading dialog
 */
export function hideLoadingDataMessage(instant = false) {
    if (instant) {
        $('.bootbox.modal').modal('hide');
    } else {
        setTimeout(function () {
            $('.bootbox.modal').modal('hide');
            // bootbox.hideAll();
        }, 1000);
    }
}

/**
 * Show bootbox alert message common function
 *
 * @param message
 * @param type
 * @param extraTimeToShow
 */
export function showAlertMessage(message, type = 'success', extraTimeToShow = 0) {
    $.jnoty(message, {
        position: 'bottom-right',
        appendTo: 'body',
        glue: 'after',
        theme: type,
        themeState: 'highlight',
        corners: '5px',
        check: 250,
        life: 5000 + extraTimeToShow,
        closeDuration: 'normal',
        openDuration: 'normal',
        easing: 'swing',
        animateOpen: {
            opacity: 'show',
        },
        animateClose: {
            opacity: 'hide',
        },
    });
}
